<template>
  <p data-component-name="Copyright">
    © {{ year }} Object First (US) Inc. |

    <NuxtLink
      :to="localePathWithSlash(PATHS.PRIVACY_POLICY.path)"
      target="_blank"
      class="nowrap"
    >
      Privacy Policy
    </NuxtLink>
    |
    <LinkDSAR token="link-reversed" />
  </p>
</template>

<script setup lang="ts">
// Components
import LinkDSAR from '~/components/redesign/LinkDSAR.vue'

import { PATHS } from '~/constants/nav-items';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

const year = new Date().getFullYear();
const localePathWithSlash = useLocalePathWithSlash();
</script>

<style scoped lang="scss">
@import "$/functions/token";
@import "$/mixins/typography";

p[data-component-name="Copyright"] {
  color: token('text-tertiary');
  @include body-3;
  text-align: center;

  a {
    color: token('link-reversed');
    @include link-text-3;

    transition: background-color 0.15s ease;

    &:hover {
      background-color: rgba(token('link-reversed'), 16%);
    }
  }
}
</style>
