<template>
  <div
    v-on-click-outside="clickOutsideHandler"
    data-component-name="LanguageSwitcherDesktop"
  >
    <TheHeaderItem
      :focused="opened"
      @click="setOpened(!opened)"
    >
      {{ locale }}
    </TheHeaderItem>

    <TheHeaderItemList :visible="opened">
      <NavItem
        v-for="{ code, i18nKey } of locales"
        :key="code"
        :to="code === 'pt' ? '#' : switchLocalePathWithSlash(code)"
        :selected="locale === code"
        component="NuxtLink"
        @click="() => {
          setOpened(!opened)
        }"
      >
        {{ $t(i18nKey) }}
      </NavItem>
    </TheHeaderItemList>
  </div>
</template>

<script setup lang="ts">
// components
import NavItem from '~/components/redesign/NavItem.vue';
import TheHeaderItem from '~/components/redesign/TheHeaderItem.vue';
import TheHeaderItemList from '~/components/redesign/TheHeaderItemList.vue';

// composable
import { useI18n } from '#i18n';
import onRouteChange from '~/composables/onRouteChange';
import useSwitchLocaleWithSlash from '~/composables/useSwitchLocaleWithSlash'

// directives
import { vOnClickOutside } from '@vueuse/components';

const { locale, locales } = useI18n();
const switchLocalePathWithSlash = useSwitchLocaleWithSlash();

const opened = ref(false);
const setOpened = (value: boolean) => {
  opened.value = value;
};
const clickOutsideHandler = () => {
  if (opened.value) {
    setOpened(false);
  }
};

onRouteChange(() => setOpened(false));
</script>

<style scoped lang="scss">
@import "$/functions/token";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/variables/shadows";

[data-component-name="LanguageSwitcherDesktop"] {
  position: relative;
  @include subtitle-3;

  user-select: none;

  [data-component-name="TheHeaderItem"] {
    text-transform: uppercase;
  }

  [data-component-name="TheHeaderItemList"] {
    width: 17.5rem;
  }
}
</style>
